import React from 'react';
import { graphql, Link } from 'gatsby';
import { Layout, SEO, ContentSection } from '@egonoid/gatsby-theme-common';

// import styles from './page.module.scss';

interface IProps {
  pageContext: any;
  data: any;
  location: any;
}

const Tags: React.FC<IProps> = ({ pageContext, data, location }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${tag}"`;

  return (
    <Layout location={location} title={tagHeader} showLogo={true}>
      <SEO title={tagHeader} />
      <ContentSection>
        <h1>{tagHeader}</h1>
      </ContentSection>
      <ContentSection>
        <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields;
            const { title } = node.frontmatter;
            return (
              <li key={slug}>
                <Link to={slug}>{title}</Link>
              </li>
            );
          })}
        </ul>
        <Link to="/tags">All tags</Link>
      </ContentSection>
    </Layout>
  );
};

export default Tags;

export const query = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      filter: {
        frontmatter: { template: { eq: "post" }, tags: { in: [$tag] } }
        fields: { isVisible: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
